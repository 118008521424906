<template src="./TextChat.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
import { Chat } from "vue-quick-chat";
import "vue-quick-chat/dist/vue-quick-chat.css";
import { EventBus } from "../../../Event"

export default {
  mixins: [mixins],
  components: {
    Chat,
  },
  props: {
    room: Object,
    professionalName: String,
    specialty: String,
    crm: String,
    photo: String,
  },
  created() {
    EventBus.$on("text-chat-receive", (message) => {
      var msg = JSON.parse(message);
      msg.author = "Doctor"
      msg.myself = false,
      this.messages.push(msg);
    })
  },
  data() {
    return {
      visible: true,
      participants: [
        {
          name: "Profissional da saúde",
          id: null,
          profilePicture:
            this.photo.length > 0 ? this.photo : this.photo,
        },
      ],
      myself: {
        name: "Paciente",
        id: null,
        profilePicture:
          "https://upload.wikimedia.org/wikipedia/commons/d/d0/Nikola-tesla-3909844.jpg",
      },
      messages: [],
      chatTitle: "Sala de atendimento",
      placeholder: "Escreva e envie sua mensagem",
      colors: {
        header: {
          bg: "#FF9F43",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#bdb8b8",
          },
          others: {
            bg: "#FF9F43",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#E56B1F",
        submitImageIcon: "#FF9F43",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: true,
      submitIconSize: 25,
      closeButtonIconSize: "20px",
      asyncMode: false,
      toLoad: [],
      scrollBottom: {
        messageSent: true,
        messageReceived: false,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      timestampConfig: {
        format: "HH:mm",
        relative: false,
      },
      // there are other options, you can check them here
      // https://soapbox.github.io/linkifyjs/docs/options.html
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  methods: {
    onMessageSubmit: function (message) {
      console.log("A MENSAGEM: ")
      const msg = JSON.stringify(message);
      console.log(msg)

      this.sendMsg(msg, this.room)
      this.messages.push(message);
    },
    sendMsg(msg, room) {
      var LocalDataTrack;
      room.localParticipant.dataTracks.forEach(function(x){
        LocalDataTrack = x;
      })
      try{
        LocalDataTrack.track.send(msg);
      } catch(x){
        console.log("Try erro: ", x);
      }
    },
    receiveMessage(msg) {
      console.log(msg)
      this.messages.push(msg);
    },
    onClose() {
      this.visible = false;
    },
    buildChatMsgObj(txt) {
      const now = new Date();
      var obj = {
        author: "user1",
        type: "text",
        name: "user1",
        content: txt,
        participantId: null,
        timestamp: {
          year: now.getFullYear(),
          month: now.getMonth(),
          day: now.getDate(),
          hour: now.getHours(),
          minute: now.getMinutes(),
          second: now.getSeconds(),
          millisecond: now.getMilliseconds(),
        },
      }
      console.log(obj)
      return obj;
    },
  },

}

</script>

<style lang="scss" src="./TextChat.scss">
</style>