<template src="./waitingRoom.html"></template>

<script>

import { EventBus } from "../../../Event"
import { isSupported, createLocalVideoTrack, createLocalAudioTrack, LocalDataTrack, connect } from "twilio-video"
import mixins from "../../../mixins/mixins.js";
import TextChat from "../../../components/twilio/text-chat/TextChat"
export default {
  mixins: [mixins],
  components: {
    TextChat,
  },
  data() {
    return {
      patientName: "Nome paciente",
      patientAge: "31 anos",
      patientGender: "feminino",
      professionalName: "Nome profissional",
      professionalRegistrationDocument: "PR - 000000",
      specialty: "",
      nameFirstUserOn: "Nome Paciente",
      dateSchedule: "03/09/2020",
      colorButtonStart: "warning",
      doctorInRoom: false,
      patientInRoom: false,
      setedInterval: "",
      previewWaitingRoom: true,
      imageData: "",
      input: null,
      clickedPhoto: false,
      checkPatientInRoom: false,

      waitingRoom: true,
      audioLevel: 0,
      username: "",
      room_name: "",
      localTrack: false,
      minhaTrackVideo: undefined,
      minhaTrackAudio: undefined,
      minhaTrackData: undefined,
      minhaUserMedia: undefined,
      loading: false,
      outroParticipante: undefined,
      hasParticipant: false,
      room: undefined,
      microphone: true,
      camera: true,
      supportsTouch: undefined,

    };
  },
  created: function () {
    if (isSupported) {
      console.log("Browser supports video")
    } else {
      console.error("This browser is not supported by our video solution.");
    }
    this.supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;

    window.addEventListener("beforeunload", this.leaveRoomIfJoined);
    this.loading = true;

    EventBus.$on("text-chat-emmit", (message) => {
      this.sendMsg(message);
    })
  },
  mounted: function () {
    const vm = this;
    if (!vm.checkPatientInRoom) {
      vm.getScheduleInfo()
        .then(() => vm.tryCamera());
    }
  },
  beforeRouteLeave(to, from, next) {
    const vm = this;
    vm.leaveRoomIfJoined();
    vm.stopTracks();
    next();
  },
  methods: {
    async tryCamera(){
      var vm = this;
      navigator.mediaDevices.getUserMedia({ audio: true, video: true })
        .then((x) => {console.log("DEU SIM", x); vm.minhaUserMedia = x})
        .catch((err) => {
          if(err.name === "NotAllowedError" || err.name === "PermissionDeniedError"){  //  Permission dismissed
            console.log("Voce negou acesso a camera")
            if (vm.supportsTouch) {
              console.log("mostra modal acesso a camera mobile")
              vm.$bvModal.show("modal-cam-chrome-mobile");
            } else {
              console.log("mostra modal acesso a camera desktop")
              vm.$bvModal.show("modal-cam-chrome");
            }
          }
          if(err.name === "NotFoundError"){  //  Requested device not found
            this.$bvModal.show("modal-camera-not-found")
          }
        });

      let token = await this.twilioToken();
      token = token.data.token

      this.leaveRoomIfJoined();
      if(!this.waitingRoom) {document.getElementById("remoteTrack").innerHTML = "";}
      this.room = await connect(token, { name: "my-cool-room", video: false, audio: false , region: "br1"	});
      this.loading = false;
      this.outroParticipante = this.room.participants.entries().next();
      this.hasParticipant = (this.outroParticipante === undefined || this.outroParticipante.value === undefined || this.outroParticipante.size === 0) ? false : true;

      this.subscribeListeners(this.room);
      //await new Promise(resolve => setTimeout(resolve, 8000)); // 3 sec
    },
    subscribeListeners(room) {
      var vm = this;
      room.on("participantConnected", function(participant) {
        console.log("participantConnected", participant)
        vm.hasParticipant = true;
        if(!vm.waitingRoom) {
          const previewContainer = document.getElementById("remoteTrack");
          vm.attachParticipantTracks(participant, previewContainer);
        }
        vm.outroParticipante = participant;
      });
      room.on("trackAdded", function(track, participant) {
        console.log("trackAdded - track", track, " - ", participant.identity)
        if(!vm.waitingRoom) {
          const previewContainer = document.getElementById("remoteTrack");
          vm.attachTracks([track], previewContainer);
        }
      });
      room.on("trackRemoved", function(track, participant) {
        console.log("trackRemoved - track", track, " - ", participant.identity)
        if(!vm.waitingRoom) {
          vm.detachTracks([track]);
        }
      });
      room.on("participantDisconnected", function(participant) {
        console.log("participantDisconnected", participant)
        vm.outroParticipante = undefined;
        vm.hasParticipant = false;
        //vm.detachParticipantTracks(participant);
      });
      // if local preview is not active, create it
      room.on("trackSubscribed", function(track, publication, participant) {
        console.log("trackSubscribed - track", track, " - ", participant.identity)
        if (track.kind === "data") {
          track.on("message", function(msg) {
            vm.receiveMessage(msg, participant);
          });
        }
        if (track.kind === "video" || track.kind === "audio") {
          const previewContainer = document.getElementById("remoteTrack");
          if(previewContainer) {
            previewContainer.appendChild(track.attach());
          }
        }
      });
    },
    confirmEnterRoomData() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
          "appointments/checkout-room/" +
          vm.$route.params.medRecId,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
    },
    async enterRoom() {
      var vm = this;
      console.log("enterRoom ")
      this.waitingRoom = false;
      vm.confirmEnterRoomData();
      await new Promise(resolve => setTimeout(resolve, 1000)); // 1 sec
      await vm.createLocalTracks();

      const myMediaContainer = document.getElementById("localTrack");
      //myMediaContainer.appendChild(this.minhaTrackVideo.attach());
      this.room.localParticipant.videoTracks.forEach(function(videoTrack) {
        myMediaContainer.appendChild(videoTrack.track.attach());
      });
      this.room.localParticipant.audioTracks.forEach(function(audioTrack) {
        myMediaContainer.appendChild(audioTrack.track.attach());
      });

      console.log("myMediaContainer", myMediaContainer)
      if(this.room.participants) {
        const hisMediaContainer = document.getElementById("remoteTrack");
        this.room.participants.forEach(participant => {
          vm.attachParticipantTracks(participant, hisMediaContainer);
          console.log("hisMediaContainer", hisMediaContainer)
        })
      }
    },

    iniciarAtendimento(){
      var vm = this;
      if(!vm.waitingRoom) {
        vm.room.participants.forEach(function(participant) {
          var previewContainer = document.getElementById("remoteTrack");
          vm.attachParticipantTracks(participant, previewContainer);
        });
      }
    },

    async createLocalTracks(){
      var vm = this;
      console.log("adding track")
      createLocalVideoTrack().then((track) => {
        vm.room.localParticipant.publishTrack(track)
        vm.minhaTrackVideo = track;
      })
      createLocalAudioTrack().then((track) => {
        vm.room.localParticipant.publishTrack(track)
        vm.minhaTrackAudio = track;
      })
      vm.minhaTrackData = await LocalDataTrack();
      vm.room.localParticipant.publishTrack(vm.minhaTrackData)
      //vm.minhaTrackData = track;
      await new Promise(resolve => setTimeout(resolve, 2000)); // 2 sec
    },

    detachTracks(tracks) {
      console.log("detachTracks(tracks)", tracks)
      tracks.forEach((track) => {
        track.detach().forEach((detachedElement) => {
          detachedElement.remove();
        });
      });
    },

    async getLocalCamera() {
      var vm = this;
      if (!vm.localTrack) {
        await createLocalVideoTrack().then((track) => {
          vm.minhaTrackVideo = track;
          createLocalAudioTrack().then((track) => {
            vm.minhaTrackAudio = track;
          })
        })
          .catch((err) => {
            console.log(err.name)
            console.log(err.message)
            if(err.name === "NotAllowedError" || err.name === "PermissionDeniedError"){  //  Permission dismissed
              console.log("Voce negou acesso a camera")
              if (vm.supportsTouch) {
                console.log("mostra modal acesso a camera mobile")
                vm.$bvModal.show("modal-cam-chrome-mobile");
              } else {
                console.log("mostra modal acesso a camera desktop")
                vm.$bvModal.show("modal-cam-chrome");
              }
            }
            if(err.name === "NotFoundError"){  //  Requested device not found
              this.$bvModal.show("modal-camera-not-found")
            }
            throw (new Error("The message"));
          });

        vm.minhaTrackData = new LocalDataTrack();
        vm.localTrack = true;

      }
    },
    async twilioToken() {
      var vm = this;
      return vm.axios
        .get(`${vm.$store.state.filooServer}appointments/session-token/${vm.$route.params.medRecId}`, {
          headers: {Authorization: vm.$session.get("bearer")}})
    },

    attachTracks(tracks, container) {
      container.appendChild(tracks.attach());
    },

    leaveRoomIfJoined() {
      if (this.room) {
        this.room.disconnect();
        this.setDepartureTime();
      }
    },
    setDepartureTime() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
          "appointments/departure/" +
          vm.$route.params.medRecId,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
    },
    stopTracks() {
      if(this.minhaTrackVideo){
        this.minhaTrackVideo.stop()
      }
      if(this.minhaTrackAudio){
        this.minhaTrackAudio.stop()
      }
      if(this.minhaUserMedia.getTracks()){
        this.minhaUserMedia.getTracks().forEach(function(track){track.stop();})
      }
    },
    mute_audio() {
      this.room.localParticipant.audioTracks.forEach(function(audioTrack) {
        console.log("audioTrack-- " + audioTrack);
        audioTrack.track.disable();
      });
      this.microphone = false;
    },
    unmute_audio() {
      this.room.localParticipant.audioTracks.forEach(function(audioTrack) {
        console.log("audioTrack-- " + audioTrack);
        audioTrack.track.enable();
      });
      this.microphone = true;
    },
    mute_video() {
      this.room.localParticipant.videoTracks.forEach(function(videoTrack) {
        console.log("videoTrack-- " + videoTrack);
        videoTrack.track.disable();
      });
      this.camera = false;
    },
    unmute_video() {
      this.room.localParticipant.videoTracks.forEach(function(videoTrack) {
        console.log("videoTrack-- " + videoTrack);
        videoTrack.track.enable();
      });
      this.camera = true;
    },
    receiveMessage(msg, part) {
      console.log(JSON.stringify(msg))
      console.log(part)
      EventBus.$emit("text-chat-receive", msg);
    },

    async attachParticipantTracks(participant, container) {
      var trackvideo, trackaudio;
      participant.tracks.forEach(function(tr){
        if(tr.kind === "video") {
          trackvideo = tr;
        }
        if(tr.kind === "audio") {
          trackaudio = tr;
        }
      })
      await new Promise(resolve => setTimeout(resolve, 3000)); // 3 sec
      if(trackvideo)
        this.attachTracks(trackvideo.track, container);
      if(trackaudio)
        this.attachTracks(trackaudio.track, container);
    },
    returnPage(){
      const vm = this;
      vm.leaveRoomIfJoined();
      vm.$router.push({ name: "scheduled" });
    },
    async getScheduleInfo() {
      const vm = this;
      vm.axios
        .get(
          vm.$store.state.filooServer +
            "appointments/waiting-room/" +
            vm.$route.params.medRecId,
          {
            headers: { Authorization: vm.$session.get("bearer") },
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            vm.professionalName = response.data.doctorName;
            vm.patientName = response.data.patientName;
            vm.patientAge = response.data.patientAge + " anos";
            vm.professionalRegistrationDocument =
              response.data.crmState + " - " + response.data.crmNumber;
            vm.nameFirstUserOn = response.data.patientName;
            if (!vm.testNullUndefinedEmpty(response.data.doctorPhoto)){
              vm.$session.set("doctorPhoto", `data:image/png;base64,${ response.data.doctorPhoto}`)
              vm.imageData = vm.testNullUndefinedEmpty(response.data.doctorPhoto) ? null : `data:image/png;base64,${response.data.doctorPhoto}`;
            } else if (!vm.testNullUndefinedEmpty(vm.$session.get("clinicPhoto")))
              vm.imageData = vm.$session.get("clinicPhoto");
            else vm.imageData = null;
            vm.specialty = response.data.specialty.name;
            if(response.data.specialty.name == "" || response.data.specialty.name == null){
              vm.specialty = "Não informado";
            }
            vm.$session.set("specialty", vm.specialty);
            vm.dateSchedule = vm.formatDate(response.data.dataConsulta);
            switch (response.data.gender) {
              case "M":
                vm.patientGender = "Masculino";
                break;
              case "F":
                vm.patientGender = "Feminino";
                break;
              default:
                vm.patientGender = "";
                break;
            }
            vm.previewWaitingRoom = false;
          }
        })
        .catch(function (error) {
          console.error(error);
        })
        .finally(() => {return})
    },
    returnAfterCheck() {
      const vm = this;
      vm.$router.push({ name: "scheduled" });
    },
    verficaWkWebView() {
      var vm = this;
      if (navigator.platform.substr(0, 2) === "iP") {
        //iOS (iPhone, iPod or iPad)
        var lte9 = /constructor/i.test(window.HTMLElement);
        var nav = window.navigator,
          ua = nav.userAgent,
          idb = !!window.indexedDB;
        if (
          ua.indexOf("Safari") !== -1 &&
          ua.indexOf("Version") !== -1 &&
          !nav.standalone
        ) {
          //Safari (WKWebView/Nitro since 6+)
        } else if ((!idb && lte9) || !window.statusbar.visible) {
          //UIWebView
        } else if (
          (window.webkit && window.webkit.messageHandlers) ||
          !lte9 ||
          idb
        ) {
          //WKWebView
          vm.isWkWebView = true;
        }
      }
    },
    checkLivingRoom() {
      this.$bvModal.show("check-living-room")
    },
    leavingRoom() {
      this.$router.push({ name: "scheduled" });
    },
    test() {
      console.log("this.room", this.room)
      console.log("this.room.participants.size", this.room.participants.size)
      console.log("this.data", this.data)
      console.log("this.localTrack", this.localTrack)
      console.log("this.remoteTrack", this.remoteTrack)
      console.log("this.previewTracks", this.previewTracks)
      console.log("this.outroParticipante", this.outroParticipante)
    },
    formatDate(dateStamp){
      const date = dateStamp.substring(0, 10).split("-");
      return `${date[2]}/${date[1]}/${date[0]}`;
    },
  },
};
</script>

<style lang="scss" src="./waitingRoom.scss">
</style>
